import { CommonModule, DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostListener, Inject } from "@angular/core";

@Component({
    selector: "app-scroll-to-top",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./scroll-to-top.component.html",
    styleUrl: "./scroll-to-top.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollToTopComponent {
    windowScrolled: boolean = false;
    constructor(@Inject(DOCUMENT) private document: Document) {}
    @HostListener("window:scroll", [])
    onWindowScroll() {
        if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
            this.windowScrolled = true;
        } else if (
            (this.windowScrolled && window.pageYOffset) ||
            document.documentElement.scrollTop ||
            document.body.scrollTop < 10
        ) {
            this.windowScrolled = false;
        }
    }
    scrollToTop() {
        (function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - currentScroll / 8);
            }
        })();
    }
}
