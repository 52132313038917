import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2, ViewChild } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { SsrCookieService } from "ngx-cookie-service-ssr";

@Component({
    selector: "app-dark-light-mode-switch",
    standalone: true,
    imports: [],
    providers: [CookieService],
    templateUrl: "./dark-light-mode-switch.component.html",
    styleUrl: "./dark-light-mode-switch.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DarkLightModeSwitchComponent implements OnInit {
    @ViewChild("check", { static: true }) check: any;
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private renderer: Renderer2,
        private cookieServiceSsr: SsrCookieService
    ) {}

    ngOnInit(): void {
        if (this.cookieServiceSsr.get("provjerenoColorMode") === "light") {
            this.renderer.removeClass(this._document.body, "mode-dark");
            this.check.nativeElement.checked = true;
        } else if (this.cookieServiceSsr.get("provjerenoColorMode") === "dark") {
            this.renderer.addClass(this._document.body, "mode-dark");
            this.check.nativeElement.checked = false;
        }
    }

    changeTheme() {
        if (this._document.body.classList.contains("mode-dark")) {
            this.cookieServiceSsr.set("provjerenoColorMode", "light", 30);

            this.renderer.removeClass(document.body, "mode-dark");
        } else {
            this.renderer.addClass(document.body, "mode-dark");
            this.cookieServiceSsr.set("provjerenoColorMode", "dark", 30);
        }
    }
}
