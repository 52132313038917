import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SubmenuItem } from '../../../interfaces/header/submenu-item.model';

@Component({
  selector: 'app-header-submenu',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header-submenu.component.html',
  styleUrl: './header-submenu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSubmenuComponent implements OnInit {
  @Input() subcategory: SubmenuItem[] = [];
  @Input() hideMenu: string = '';
  @Input() submenuHide: boolean = false;
  @Output() isMenuShown = new EventEmitter<string>();
  blackHeader: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (environment.APP_BLACK_HEADER === '1') {
      this.blackHeader = true;
    }
  }

  onMenuClose() {
    this.isMenuShown.emit('');
  }
}
