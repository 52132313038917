import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Nav } from '../../interfaces/header/nav.model';
import { NavService } from '../../services/nav.service';
import { DarkLightModeSwitchComponent } from './dark-light-mode-switch/dark-light-mode-switch.component';
import { HeaderSearchComponent } from './header-search/header-search.component';
import { HeaderSubmenuComponent } from './header-submenu/header-submenu.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderSubmenuComponent,
    HeaderSearchComponent,
    DarkLightModeSwitchComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'relative',
  },
})
export class HeaderComponent implements OnInit {
  isMenuShown: boolean = false;

  nav$: Observable<Nav> = this.navService.allNav$.pipe(
    filter((res) => res !== null)
  );
  blackHeader: boolean = false;
  logoUrl = './images/logo-mobile.webp';
  constructor(private navService: NavService) {}
  submenuHide: boolean = false;
  submenuHideArray: boolean[] = [];

  ngOnInit(): void {
    this.navService.getNav();

    if (environment.APP_BLACK_HEADER === '1') {
      this.blackHeader = true;
      this.logoUrl = './assets/images/logo-dark.png';
    }
  }

  onMenuOpen() {
    this.isMenuShown = !this.isMenuShown;
    if (this.isMenuShown === false) {
      this.submenuHideArray = this.submenuHideArray.map(() => false);
    }
  }
  closeMenu() {
    if (this.isMenuShown) {
      this.isMenuShown = false;
      this.submenuHideArray = this.submenuHideArray.map(() => false);
    }
  }
  onCollapseSubmenu(index: number) {
    if (this.submenuHideArray[index] === undefined) {
      this.submenuHideArray[index] = false;
    }

    this.submenuHideArray[index] = !this.submenuHideArray[index];
  }
  closeFromSubmenu(close: string) {
    this.onMenuOpen();
  }

  closeOnSearch(close: string) {
    this.closeMenu();
  }
}
