<div class="scroll-to-top bg-gray-dark lg:hidden" [ngClass]="{ 'show-scrollTop': windowScrolled }">
    <button
        type="button"
        class="block w-full h-full grid place-items-center"
        data-toggle="button"
        aria-pressed="true"
        (click)="scrollToTop()">
        <svg
            width="19"
            height="11"
            viewBox="0 0 19 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="stroke-white">
            <path d="M2 9L9.5 1.9763L17 9" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </button>
</div>
