@if(!underConstruction){ @if(!blackHeader()) {
<header class="bg-white-black sticky top-0 z-50 xl:shadow-none shadow-lg">
    <app-header></app-header>
</header>
} @else {
<header class="bg-cinder sticky top-0 z-50">
    <app-header></app-header>
</header>
}
<section>
    <app-notification></app-notification>
</section>

}

<router-outlet />
@if(!underConstruction){
<footer class="bg-soft-peach">
    <app-footer></app-footer>
</footer>

}
