import { Injectable } from "@angular/core";
import { CanMatch, Route, UrlSegment, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { CategorysService } from "./category.service";

@Injectable({
    providedIn: "root",
})
export class CanMatchService implements CanMatch {
    constructor(private categoryService: CategorysService) {}

    canMatch(
        route: Route,
        segments: UrlSegment[]
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.categoryService.getCategoryTest(segments[1].path);
    }
}
