<ng-container *ngIf="(notification$ | async)?.data as notification">
  <div class="container px-3" *ngIf="notification.id && notification.title">
    <a
      *ngIf="notification.url; else nolink"
      [href]="notification.url"
      target="_blank"
    >
      <div class="container-notification px-3 py-1.5 w-full bg-ferrari-red">
        <p class="font-semibold text-lg scrolling-text">
          {{ notification.title }}
        </p>
      </div>
    </a>
    <ng-template #nolink>
      <div class="container-notification px-3 py-1.5 w-full">
        <p class="font-semibold text-lg scrolling-text">
          {{ notification.title }}
        </p>
      </div>
    </ng-template>
  </div>
</ng-container>
