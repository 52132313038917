import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { RouterModule } from "@angular/router";
import { filter, Observable } from "rxjs";
import { Nav } from "../../interfaces/header/nav.model";
import { Pages } from "../../interfaces/header/pages.model";
import { NavService } from "../../services/nav.service";
import { FooterNavComponent } from "./footer-nav/footer-nav.component";
import { FooterSocialComponent } from "./footer-social/footer-social.component";

@Component({
    selector: "app-footer",
    standalone: true,
    imports: [RouterModule, CommonModule, FooterNavComponent, FooterSocialComponent],
    templateUrl: "./footer.component.html",
    styleUrl: "./footer.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit, OnDestroy {
    nav$: Observable<Nav> = this.navService.allNav$.pipe(filter((res) => res !== null));
    pages$: Observable<Pages> = this.navService.footerPages$.pipe(filter((res) => res !== null));

    constructor(private navService: NavService) {}

    ngOnInit(): void {
        this.navService.getFooterPages();
    }

    ngOnDestroy(): void {}
}
