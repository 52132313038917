import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Nav } from "../../../interfaces/header/nav.model";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

@Component({
    selector: "app-footer-nav",
    standalone: true,
    imports: [RouterModule, CommonModule],
    templateUrl: "./footer-nav.component.html",
    styleUrl: "./footer-nav.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterNavComponent {
    @Input() navList = {} as Nav;
}
