import { ViewportScroller } from "@angular/common";
import { AfterViewInit, Component, OnInit, signal } from "@angular/core";
import { Router, RouterOutlet, Scroll } from "@angular/router";
import { delay, filter } from "rxjs";
import { register } from "swiper/element/bundle";
import { environment } from "../environments/environment";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { ScrollToTopComponent } from "./components/scroll-to-top/scroll-to-top.component";

register();
@Component({
    selector: "app-root",
    standalone: true,
    imports: [RouterOutlet, HeaderComponent, NotificationComponent, FooterComponent, ScrollToTopComponent],
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit, AfterViewInit {
    blackHeader = signal(false);
    underConstruction = environment.APP_UNDER_CONSTRUCTION === "1";
    constructor(private router: Router, private viewportScroller: ViewportScroller) {}

    ngOnInit(): void {
        if (environment.APP_BLACK_HEADER === "1") {
            this.blackHeader.set(true);
        }
    }

    ngAfterViewInit(): void {
        this.router.events
            .pipe(filter((event) => event instanceof Scroll))
            .pipe(delay(400))
            .subscribe((e: any) => {
                if (e.position) {
                    this.viewportScroller.scrollToPosition(e.position);
                } else {
                    this.viewportScroller.scrollToPosition([0, 0]);
                }
            });
    }
}
