<div class="container px-3 z-50">
    <div class="justify-between items-center py-2 xl:flex hidden">
        <!-- Header logo -->
        <a routerLink="/">
            <img src="images/header-logo.webp" alt="" />
        </a>
        <!-- Header banner -->
        <!-- <div>
            <img src="images/header-banner.webp" alt="" />
        </div> -->
    </div>
    <div class="flex justify-between xl:border-y-2 gap-3 xl:py-0 py-4">
        <!-- Header logo -->
        <a routerLink="/" class="xl:hidden" (click)="closeMenu()">
            <img src="images/header-logo.webp" alt="" />
        </a>
        <div class="flex xl:z-10">
            <nav
                class="pl-3 top-0 absolute xl:h-min h-screen w-full left-0 xl:static xl:flex items-center -z-10 xl:overflow-visible transition-transform xl:translate-y-0 bg-white-black"
                [ngClass]="{
                    '-translate-y-full': !isMenuShown,
                    'bg-gray-dark': !blackHeader,
                    'bg-cinder': blackHeader
                }">
                <ul
                    *ngIf="nav$ | async as navList"
                    class="flex flex-col xl:flex-row xl:gap-10 xl:items-center font-bold xl:mt-0 mt-20 container xl:px-0 px-3">
                    <!-- <li
            [ngClass]="{
              'text-dark-jungle-green': !blackHeader,
              'text-white-strong': blackHeader
            }"
            class="xl:mb-0 relative menu-item xl:pr-0 no-tap-color uppercase xl:text-base text-lg py-2"
          >
            <a
              routerLink="/"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="onMenuOpen()"
              class="hover:text-ferrari-red transition-colors font-bold"
              >POČETNA</a
            >
          </li> -->
                    <li
                        class="xl:mb-0 hover:text-ferrari-red relative menu-item xl:pr-0 no-tap-color xl:py-5 py-2 uppercase xl:text-base text-lg"
                        *ngFor="let item of navList.data; let i = index"
                        [ngStyle]="{ color: item.color }"
                        [ngClass]="{
                            'text-dark-jungle-green': !blackHeader,
                            'text-white-strong': blackHeader
                        }">
                        <div class="flex justify-between items-center">
                            <a
                                class="hover:text-blue transition-colors"
                                [routerLink]="item.slug"
                                routerLinkActive="active"
                                (click)="onMenuOpen()"
                                >{{ item.title }}</a
                            >
                            <button *ngIf="item.children.length > 0" class="xl:hidden" (click)="onCollapseSubmenu(i)">
                                <svg
                                    width="19"
                                    height="11"
                                    viewBox="0 0 19 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="transition-transform"
                                    [ngClass]="{
                                        'stroke-dark-jungle-green': !blackHeader,
                                        'stroke-white': blackHeader,
                                        'rotate-180': submenuHideArray[i],
                                        'rotate-90': !submenuHideArray[i]
                                    }">
                                    <path
                                        d="M2 9L9.5 1.9763L17 9"
                                        stroke-width="3"
                                        stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </button>
                        </div>
                        <app-header-submenu
                            *ngIf="item.children.length > 0"
                            [subcategory]="item.children"
                            (isMenuShown)="closeFromSubmenu($event)"
                            [submenuHide]="submenuHideArray[i]"></app-header-submenu>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="flex xl:w-fit w-full items-center space-x-4">
            <app-header-search (isMenuShown)="closeOnSearch($event)"></app-header-search>
            <app-dark-light-mode-switch></app-dark-light-mode-switch>
            <!-- <app-header-select-site></app-header-select-site> -->
            <button class="xl:hidden ml-3 sm:ml-9 no-tap-color" (click)="onMenuOpen()">
                <svg
                    width="27"
                    height="21"
                    viewBox="0 0 27 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    [ngClass]="{
                        'fill-dark-jungle-green': !blackHeader,
                        'fill-white-strong': blackHeader
                    }">
                    <path
                        d="M0 0.96875H26.6667V4.25689H0V0.96875ZM0 9.1891H26.6667V12.4772H0V9.1891ZM0 17.4094H26.6667V20.6976H0V17.4094Z" />
                </svg>
            </button>
        </div>
    </div>
</div>
