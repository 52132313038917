<ul
  class="submenu z-50 overflow-hidden max-h-0 xl:max-h-screen xl:py-3 xl:absolute pl-10 xl:px-4 xl:top-full xl:left-0 xl:min-w-[10rem] xl:hidden shadow-none xl:shadow-md xl:opacity-9 xl:after:absolute xl:after:top-0 xl:after:left-0 xl:after:w-1/2 xl:after:h-[3px] xl:after:bg-ferrari-red xl:after:content-['']"
  [ngClass]="{
    'max-h-96': submenuHide,
    'bg-white-black': !blackHeader,
    'bg-cinder': blackHeader
  }"
>
  <li
    class="mb-2 last:mb-0 transition-colors xl:uppercase normal-case first-letter:uppercase xl:text-base text-lg"
    [ngClass]="{
      'text-dark-jungle-green': !blackHeader,
      'text-white': blackHeader
    }"
    *ngFor="let subItem of subcategory"
    [ngStyle]="{ color: subItem.color }"
  >
    <a
      class="hover:text-ferrari-red"
      [routerLink]="subItem.slug"
      (click)="onMenuClose()"
    >
      {{ subItem.title }}
    </a>
  </li>
</ul>
