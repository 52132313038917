import { ApplicationConfig, provideZoneChangeDetection } from "@angular/core";
import { provideRouter, withInMemoryScrolling } from "@angular/router";

import { provideHttpClient, withFetch } from "@angular/common/http";
import { provideClientHydration } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha-2";
import { environment } from "../environments/environment";
import { routes } from "./app.routes";
import { routesUnderConstruction } from "./app.under-construction.routes";

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(
            environment.APP_UNDER_CONSTRUCTION === "1" ? routesUnderConstruction : routes,
            withInMemoryScrolling({
                scrollPositionRestoration: "enabled",
            })
        ),
        provideClientHydration(),
        provideAnimations(),
        provideHttpClient(withFetch()),
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.APP_RECAPTCHA_PUBLIC_KEY },
    ],
};
