<form
  class="flex w-full justify-end"
  #searchForm="ngForm"
  (ngSubmit)="onSearch(searchForm)"
>
  <div
    class="w-full scale-x-0 border-b-2 transition-transform sm:relative absolute bottom-0 sm:translate-y-0 translate-y-full right-0"
    [ngClass]="isShown"
  >
    <input
      class="w-full border-0 py-2 px-3 placeholder:font-bold placeholder:text-dark-jungle-green focus-visible:outline-none sm:bg-transparent bg-white-black text-dark-jungle-green"
      placeholder="Pretraži..."
      type="search"
      name="search"
      id="search"
      required
      ngModel
      #searchField
    />
  </div>
  <button
    class="stroke-dark-jungle-green hover:stroke-blue transition-colors flex items-center"
    [ngClass]="{
      'stroke-dark-jungle-green': !blackHeader,
      'stroke-white-strong': blackHeader
    }"
  >
    <svg
      class="fill-dark-jungle-green"
      width="23"
      height="23"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0889 14.5961L22.625 20.1323L20.7805 21.9781L15.2496 16.4472C13.4056 17.8359 11.104 18.4747 8.80795 18.235C6.51194 17.9953 4.39194 16.8948 2.87459 15.155C1.35725 13.4153 0.555168 11.1653 0.629774 8.85806C0.70438 6.55077 1.65013 4.35737 3.2767 2.71926C4.90328 1.08115 7.08994 0.119926 9.39664 0.0290163C11.7033 -0.0618934 13.9589 0.724264 15.7093 2.22928C17.4598 3.7343 18.5752 5.84646 18.8312 8.14072C19.0871 10.435 18.4646 12.741 17.0889 14.5948V14.5961ZM16.2775 9.15392C16.2775 7.42409 15.5903 5.76512 14.3672 4.54195C13.144 3.31878 11.485 2.63161 9.75519 2.63161C8.02537 2.63161 6.36639 3.31878 5.14322 4.54195C3.92005 5.76512 3.23289 7.42409 3.23289 9.15392C3.23289 10.8837 3.92005 12.5427 5.14322 13.7659C6.36639 14.9891 8.02537 15.6762 9.75519 15.6762C11.485 15.6762 13.144 14.9891 14.3672 13.7659C15.5903 12.5427 16.2775 10.8837 16.2775 9.15392Z"
        fill="dark-jungle-green"
      />
    </svg>
  </button>
</form>
