import { Routes } from "@angular/router";
import { CanMatchService } from "./services/can-match.service";

export const routes: Routes = [
    {
        path: "",
        loadChildren: () => import("./pages/home/home.routes").then((m) => m.routes),
    },
    {
        path: "404",
        loadChildren: () => import("./pages/not-found/not-found.routes").then((m) => m.routes),
    },
    {
        path: "under-construction",
        loadChildren: () => import("./pages/under-construction/under-construction.routes").then((m) => m.routes),
    },
    {
        path: "pretraga",
        loadChildren: () => import("./pages/search/search.routes").then((m) => m.routes),
    },
    {
        path: `sve-vijesti`,
        loadChildren: () => import("./pages/all-news/all-news.routes").then((m) => m.routes),
    },
    {
        path: "sifra/:id",
        loadChildren: () => import("./pages/created-by/created-by.routes").then((m) => m.routes),
    },

    {
        path: "p/:static",
        pathMatch: "full",
        loadChildren: () => import("./pages/static/static.routes").then((m) => m.routes),
    },

    {
        path: "tag/:tag",
        pathMatch: "full",
        loadChildren: () => import("./pages/tag/tag.routes").then((m) => m.routes),
    },

    {
        path: "autor/:author",
        pathMatch: "full",
        loadChildren: () => import("./pages/author/author.routes").then((m) => m.routes),
    },
    {
        path: "izvor/:source",
        pathMatch: "full",
        loadChildren: () => import("./pages/source/source.routes").then((m) => m.routes),
    },

    {
        path: ":category",
        pathMatch: "full",
        loadChildren: () => import("./pages/parent-category/parent-category.routes").then((m) => m.routes),
    },
    {
        path: ":category/:subcategory",
        pathMatch: "full",
        loadChildren: () => import("./pages/category-list/category-list.routes").then((m) => m.routes),
        canMatch: [CanMatchService],
    },

    {
        path: ":category/:post",
        loadChildren: () => import("./pages/single-page/single-page.routes").then((m) => m.routes),
    },
    {
        path: ":category/:subcategory/:post",
        pathMatch: "full",
        loadChildren: () => import("./pages/single-page/single-page.routes").then((m) => m.routes),
    },
    {
        path: "**",
        loadChildren: () => import("./pages/not-found/not-found.routes").then((m) => m.routes),
    },
];
