import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit, signal } from "@angular/core";
import { filter, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { NotificationBreaking } from "../../interfaces/notification/notification-breaking.model";
import { NotificationsService } from "../../services/notifications.service";

@Component({
    selector: "app-notification",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./notification.component.html",
    styleUrl: "./notification.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit {
    blackHeader = signal(false);
    notification$: Observable<NotificationBreaking> = this.notificationsService.notification$.pipe(
        filter((res) => res !== null)
    );
    constructor(private notificationsService: NotificationsService) {}
    ngOnInit(): void {
        this.notificationsService.getNotification();
        if (environment.APP_BLACK_HEADER === "1") {
            this.blackHeader.set(true);
        }
    }
}
