import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, map } from "rxjs";
import { environment } from "../../environments/environment";
import { Nav } from "../interfaces/header/nav.model";
import { Pages } from "../interfaces/header/pages.model";

@Injectable({
    providedIn: "root",
})
export class NavService {
    private allNav = new BehaviorSubject<Nav | null>(null);
    private footerPages = new BehaviorSubject<Pages | null>(null);

    allNav$ = this.allNav.asObservable();
    footerPages$ = this.footerPages.asObservable();
    constructor(private http: HttpClient) {}

    getNav() {
        return this.http.get<Nav>(`${environment.API_UNA}${environment.API_NAV}`).subscribe((nav: Nav) => {
            nav.data.forEach((e) => {
                if (e.type === "page") {
                    e.slug = "p/" + e.slug;
                } else if (e.type === "tag") {
                    e.slug = "tag/" + e.slug;
                } else if (e.type === "program-schemas") {
                    e.slug = "program";
                } else if (e.type === "article-listing") {
                    e.slug = `sve-vijesti`;
                }
                e.children.forEach((el) => {
                    if (el.type === "page") {
                        el.slug = "p/" + el.slug;
                    } else if (el.type === "tag") {
                        el.slug = "tag/" + el.slug;
                    } else if (el.type === "program-schemas") {
                        el.slug = "program";
                    } else if (el.type === "article-listing") {
                        el.slug = `sve-vijesti`;
                    }
                });
            });
            this.allNav.next(nav);
        });
    }

    getFooterPages() {
        return this.http
            .get<Pages>(`${environment.API_UNA}${environment.API_PAGES}`)
            .pipe(
                map((res) => {
                    res.data.map((e) => {
                        if (!e.slug.startsWith("p/")) e.slug = "p/" + e.slug;
                    });
                    return res;
                })
            )
            .subscribe((pages: Pages) => {
                this.footerPages.next(pages);
            });
    }
}
