<nav class="sm:pb-14 pb-4">
  <ul
    class="flex items-center justify-center text-base font-bold uppercase flex-wrap gap-4 sm:border-y-2 border-y-gainsboro"
  >
    <li
      *ngFor="let item of navList.data"
      class="text-dark-jungle-green hover:text-ferrari-red transition-colors sm:py-4"
    >
      <a [routerLink]="item.slug">{{ item.title }}</a>
    </li>
  </ul>
</nav>
