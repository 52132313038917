import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchService } from '../../../services/search.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { FormsModule, NgForm } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-header-search',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './header-search.component.html',
  styleUrl: './header-search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'w-full',
  },
})
export class HeaderSearchComponent implements OnInit, OnDestroy {
  blackHeader: boolean = false;
  isShown: string = '';
  subscriptionNav: Subscription = new Subscription();
  @Output() isMenuShown = new EventEmitter<string>();
  @ViewChild('searchField', { static: true })
  searchField: ElementRef<HTMLInputElement> = {} as ElementRef;
  constructor(private router: Router, private searchService: SearchService) {
    this.subscriptionNav = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.closeSearch();
      }
    });
  }

  ngOnInit(): void {
    if (environment.APP_BLACK_HEADER === '1') {
      this.blackHeader = true;
    }
  }

  onSearch(form: NgForm) {
    if (this.isShown === '') {
      this.isShown = 'scale-x-100';
      this.searchField.nativeElement.focus();
    } else {
      this.isShown = '';
    }
    if (form.valid) {
      this.router.navigate(['/pretraga'], {
        queryParams: { q: form.value.search },
      });
      form.reset();
    }
  }

  closeSearch() {
    this.isShown = '';
    this.isMenuShown.emit('');
  }

  ngOnDestroy(): void {
    this.subscriptionNav.unsubscribe();
  }
}
